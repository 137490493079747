import React, { useState } from "react";
import TypewriterComponent from "typewriter-effect";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Navbar } from "./navbar";

{
  /*TODO create tooltip with "what is this?" and link the status code div to a page where status-codes are explained (host on different host incase of total features) */
}

export const Landing = () => {
  return (
    <>
      {/* <div className="grid content-center absolute align-top">
        <Navbar />
      </div> */}
      <div className="grid place-items-center w-full h-full align-center content-center">
        <div className="cursor-pointer ease-linear duration-100 sd hover:shadow-xl hover:bg-gray-800 lg:w-96 md:w-38 m-0 grid flex-rows h-12 content-center ring-2 shadow-xl ring-gray-500 text-center bg-gray-900 rounded-lg mb-10">
          <h1 className="text-2xl text-white">
            We tried to reach the Page, but...
          </h1>
        </div>
      </div>
      {/**Create hints with each text element heißt wenn ich auf i gehe und clicke dann auf eine seite mit about me komme */}
      <div className="text-white select-none  text-center h-full w-full">
        <h1 className="text-4xl ease-linear duration-100">
          <br />
          <TypewriterComponent
            options={{
              strings: ["Something went wrong", "Etwas ist schiefgelaufen"],
              autoStart: true,
              loop: true,
              delay: 100,
            }}
          />
          <div className="mt-10 grid place-content-center align-center m-0">
            <div className="bg-gray-900 text-2xl  w-52 h-20 rounded ">
              <span>CODE: MAINTENANCE</span>
            </div>
          </div>
          {/* <div className="mt-10  grid place-content-center align-center m-0 w-4/12 h-10">
            <div className="bg-gray-800 rounded-sm shadow-lg">
              <span className="text-lg">
                It's clearly something on our end, check back later!
              </span>
            </div>
          </div> */}
        </h1>
      </div>
    </>
  );
};
