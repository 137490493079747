import React from "react";
import { Landing } from "./components/landing/index";

function App() {
  return (
    <div className="p-0 flex-grow m-0 min-w-screen min-h-screen grid content-center align-center flex-col h-screen w-screen bg-gradient-to-r from-slate-800 to-slate-700 ">
      <Landing />
    </div>
  );
}

export default App;
